@import '~bootstrap/scss/bootstrap';

//Custom
section.error {
  h1 {
    font-size: 4rem;
    font-weight: 700;

    @media (max-width: 1400px) {
      font-size: 3.5rem;
    }
    @media (max-width: 500px) {
      font-size: 2.5rem;
    }
  }
}

a.btn.custom {
  padding-left: 0.20rem;

  span {
    &.spinner-border.hover {
      --bs-spinner-animation-speed: 0s !important;
      width: 0 !important;
      opacity: 0 !important;
      transition: all .2s ease-in-out;
    }
  }

  &:is(&:hover,&:focus) {
    padding-left: 0.75rem;

    span.spinner-border {
      --bs-spinner-animation-speed: 1s !important;
      width: 16px !important;
      opacity: 1 !important;
    }
  }
}